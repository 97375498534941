import { ActionReducerMap } from '@ngrx/store';

export interface RootState {
  // [authorizationFeatureKey]: AuthorizationState;
  // [inventoryFeatureKey]: InventoryState;
}

export const rootReducers: ActionReducerMap<RootState> = {
  // [authorizationFeatureKey]: authorizationReducer,
  // [inventoryFeatureKey]: inventoryReducer,
};
