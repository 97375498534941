import { computed, inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { SIGNAL_BASE_URL } from '../../shared/signalr.api';
import { DevPosStore } from '../../state/devpos.store';
import { AuthorizationStore } from 'src/app/state/authorization-store';
import { ConfigurationsStore } from 'src/app/state/configurations-store';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SignalrService {
  store = inject(Store);
  devPosStore = inject(DevPosStore);
  url = inject(SIGNAL_BASE_URL);
  authorizationStore = inject(AuthorizationStore);
  readonly configurationsStore = inject(ConfigurationsStore);

  hubConnection!: HubConnection | undefined;

  companyDto = computed(() => this.authorizationStore.companyDto!() ?? undefined);
  contractId = computed(() => this.companyDto()?.contractId);
  jwtToken = computed(() => this.authorizationStore.token() ?? undefined);
  tenantIdentifier = computed(() =>
    this.companyDto()
      ? `${this.companyDto()?.nipt}|${this.companyDto()?.year}|${this.companyDto()?.businessCode}|${this.companyDto()?.isDemo}`
      : undefined,
  );
  private notificationReceived = new Subject<number>();

  constructor(
    private toast: ToastrService,
    private translate: TranslateService,
  ) {}

  get newNotificationReceived() {
    return this.notificationReceived.asObservable();
  }

  Init(): void {
    this.hubConnection = this.BuildHub();
    if (!this.hubConnection) return;
    this.hubConnection.start().then(() => this.registerSignalEvents());
  }

  Destroy(): void {
    this.hubConnection?.stop();
    // todo dispach action to show offline status badge
  }

  imAlive() {
    if (this.hubConnection?.state !== 'Connected') return;
    this.hubConnection?.invoke('PresenceReported');
  }

  private registerSignalEvents() {
    this.hubConnection!.on('WelcomeMessage', (message: string) => {
      console.log('welcomeMessage event received. message:', message);
      // todo dispach action to show online status badge
    });

    this.hubConnection!.on('DevPosSyncFinished', (tableTypeEnum: number, rowId?: number) => {
      console.log('DevPosSyncFinished event received. message:', tableTypeEnum, rowId);
      if (!rowId) this.devPosStore.unsetSyncAll(tableTypeEnum);
      else this.devPosStore.unsetSyncSingleRow(tableTypeEnum, rowId);
    });

    this.hubConnection!.on('ReportPresence', (message: string) => {
      console.log('reportPresence event received. message:', message);
      this.imAlive();
    });

    this.hubConnection!.on('NewNotification', (lastNotificationId: number) => {
      console.log('reloadNotifications event received. last id:', lastNotificationId);
      this.notificationReceived.next(lastNotificationId);
    });

    this.hubConnection!.on('ClearCache', (message: string) => {
      console.log('clearCache event received. message:', message);
      this.configurationsStore.skipCacheRequest(true, false);
    });

    this.hubConnection!.on('LogOut', (message: string) => {
      console.log('LogOut event received. message:', message);
      this.authorizationStore.logout();
      this.toast.info(this.translate.instant('auth.user_logged_out'));
    });

    this.hubConnection!.on('RefreshPermissions', () => {
      console.log('refreshPermissions event received.');
      this.authorizationStore.loadMFP(true);
    });
    this.hubConnection!.on('DevPosPostFinished', (tableTypeEnum: number, rowId: number | undefined, success: boolean) => {
      console.log('devPosPostFinished event received.');
      this.devPosStore.unsetPostRow(tableTypeEnum, rowId, success);
    });
  }

  private BuildHub() {
    const username = localStorage.getItem('attemptedUsername');
    if (!username) {
      console.error('No username found in local storage');
      return;
    }

    let signalUrl = new URL(this.url);
    signalUrl.pathname = '/api/hubs/notifications';
    signalUrl.searchParams.append('Authorization', this.jwtToken()!);
    signalUrl.searchParams.append('x-tenant-id', this.tenantIdentifier()!);
    signalUrl.searchParams.append('x-contract-id', this.contractId()!);
    signalUrl.searchParams.append('x-username', username);

    return new HubConnectionBuilder().withUrl(signalUrl.toString()).withAutomaticReconnect().withStatefulReconnect().build();
  }
}
