import { withStorageSync, withDevtools } from '@angular-architects/ngrx-toolkit';
import { inject, Injector } from '@angular/core';
import { signalStore, withState, withComputed, withMethods, patchState } from '@ngrx/signals';
import moment from 'moment';
import { firstValueFrom, take } from 'rxjs';
import { GetStateData, IStateData } from 'src/app/shared/interfaces/state/state';
import { nswagCatchOperator } from 'src/app/shared/operators/nswag-catch-operator';
import {
  CcGetAllOrFilteredRequest,
  CompanyOptionEnum,
  CompanyStructureDto,
  CostingCenterDto,
  CurrencyDto,
  DynamicPage,
  HttpClientCompanyOptions,
  HttpClientCompanyStructure,
  HttpClientCostingCenter,
  HttpClientCurrencies,
  HttpClientDevPosIntegration,
  HttpClientEndingYear,
  HttpClientPublic,
  HttpClientUser,
  KeyValuePairOfGuidAndString,
  Request2,
} from 'src/app/shared/nswag.api';
import { EXPIRE_API_SECONDS } from 'src/app/shared/constants/api-priority.seconds';
import { HttpContext } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

type CONFIGURATIONS_STATE = {
  activeCurrencies: IStateData<CurrencyDto[]>;
  decimalPlaces: IStateData<number>;
  skipCache: IStateData<boolean>;
  currentUserId: IStateData<string | undefined>;
  apiVersion: IStateData<string> | undefined;
  endingYearStarted: IStateData<boolean> | undefined;
  useForFisalization: IStateData<boolean> | undefined;
  endingYearCallStarted: boolean | undefined;
  areUsersLoading: boolean | undefined;
  cachedCostingCenters: IStateData<CostingCenterDto>[];
  cachedCompanyStructures: IStateData<CompanyStructureDto>[];
  cachedUsers: IStateData<KeyValuePairOfGuidAndString[]>;
  transferShipment: IStateData<string | undefined>;
  transferReceipt: IStateData<string | undefined>;
  costingCenters: IStateData<CostingCenterDto[] | undefined>;
};

const INITIAL_CONFIGURATIONS_STATE: CONFIGURATIONS_STATE = {
  activeCurrencies: {
    data: undefined,
    lastUpdatedDate: undefined,
    loading: false,
  },

  decimalPlaces: {
    data: undefined,
    lastUpdatedDate: undefined,
    loading: false,
  },

  apiVersion: {
    data: undefined,
    lastUpdatedDate: undefined,
    loading: false,
  },

  endingYearStarted: {
    data: undefined,
    lastUpdatedDate: undefined,
    loading: false,
  },

  currentUserId: {
    data: undefined,
    lastUpdatedDate: undefined,
    loading: false,
  },

  useForFisalization: {
    data: undefined,
    lastUpdatedDate: undefined,
    loading: false,
  },

  endingYearCallStarted: false,

  areUsersLoading: false,

  skipCache: {
    data: false,
    lastUpdatedDate: moment(),
    loading: false,
  },

  transferShipment: {
    data: undefined,
    lastUpdatedDate: undefined,
    loading: false,
  },

  transferReceipt: {
    data: undefined,
    lastUpdatedDate: undefined,
    loading: false,
  },

  cachedCostingCenters: [],

  cachedCompanyStructures: [],

  cachedUsers: {
    data: undefined,
    lastUpdatedDate: undefined,
    loading: false,
  },

  costingCenters: {
    data: undefined,
    lastUpdatedDate: undefined,
    loading: false,
  },
};

export const ConfigurationsStore = signalStore(
  { providedIn: 'root' },
  withState(INITIAL_CONFIGURATIONS_STATE),
  withComputed(state => ({})),
  withMethods(
    (
      store,
      httpClientDevPosIntegration = inject(HttpClientDevPosIntegration),
      httpClientCurrencies = inject(HttpClientCurrencies),
      httpClientCompanyOptions = inject(HttpClientCompanyOptions),
      httpClientPublic = inject(HttpClientPublic),
      httpClientCostingCenter = inject(HttpClientCostingCenter),
      httpClientCompanyStructure = inject(HttpClientCompanyStructure),
      httpClientUser = inject(HttpClientUser),
      httpClientEndingYear = inject(HttpClientEndingYear),
      toast = inject(ToastrService),
      injector = inject(Injector),
    ) => ({
      getAllGuidFullName: async (force_skip = false, httpContext?: HttpContext) => {
        if (!force_skip) {
          const users = store.cachedUsers();
          const existingData = GetStateData(users, moment(), EXPIRE_API_SECONDS.USERS, store.skipCache().data);
          if (existingData || store.areUsersLoading()) return;
        }
        patchState(store, {
          areUsersLoading: true,
        });
        const newData = await firstValueFrom(httpClientUser.getAllGuidFullName(httpContext).pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data) return;

        const dataFromApi = {
          data: newData.data,
          lastUpdatedDate: moment(),
          loading: false,
        };

        patchState(store, {
          cachedUsers: dataFromApi,
          areUsersLoading: false,
        });
      },

      getCompanyStructureById: async (force_skip = false, companyStructureId: number, httpContext?: HttpContext) => {
        if (!force_skip) {
          const costingCenter = store.cachedCompanyStructures().find(value => value.data?.id === companyStructureId);
          const existingData = GetStateData(costingCenter, moment(), EXPIRE_API_SECONDS.COMPANYSTRUCUTRE, store.skipCache().data);
          if (existingData) return;
        }

        const itemsOnState = [...(store.cachedCompanyStructures?.() || [])];

        const idx = itemsOnState.findIndex(x => x.data?.id === companyStructureId);
        if (idx > -1) {
          itemsOnState[idx].loading = true;
        } else {
          //add new item on state with loading true
          itemsOnState.push({
            data: new CompanyStructureDto({ id: companyStructureId }),
            lastUpdatedDate: undefined,
            loading: true,
          });
        }
        patchState(store, {
          cachedCompanyStructures: itemsOnState,
        });

        const newData = await firstValueFrom(
          httpClientCompanyStructure.companyStructureGetById(companyStructureId, httpContext).pipe(nswagCatchOperator(), take(1)),
        );

        if (!newData.succeeded || !newData.data) {
          let removedNotFoundedElement = [...store.cachedCompanyStructures().filter(el => el.data?.id !== companyStructureId)];
          patchState(store, {
            cachedCompanyStructures: { ...removedNotFoundedElement },
          });
          return;
        }

        const indexOfCompanyStructureId = itemsOnState.findIndex(x => x.data?.id === companyStructureId);
        if (indexOfCompanyStructureId > -1) {
          itemsOnState[indexOfCompanyStructureId].data = newData.data;
          itemsOnState[indexOfCompanyStructureId].loading = false;
          itemsOnState[indexOfCompanyStructureId].lastUpdatedDate = moment();
          patchState(store, {
            cachedCompanyStructures: [...itemsOnState],
          });
        } else {
          patchState(store, {
            cachedCompanyStructures: [
              ...store.cachedCompanyStructures().filter(el => el.data?.id !== companyStructureId),
              ...[
                {
                  data: newData.data,
                  lastUpdatedDate: moment(),
                  loading: false,
                },
              ],
            ],
          });
        }
      },

      getCostingCenterById: async (force_skip = false, costingCenterId: number, httpContext?: HttpContext) => {
        if (!force_skip) {
          const costingCenter = store.cachedCostingCenters().find(value => value.data?.id === costingCenterId);
          const existingData = GetStateData(costingCenter, moment(), EXPIRE_API_SECONDS.COSTINGCENTER, store.skipCache().data);
          if (existingData) return;
        }

        const itemsOnState = [...(store.cachedCostingCenters?.() || [])];

        const idx = itemsOnState.findIndex(x => x.data?.id === costingCenterId);
        if (idx > -1) {
          itemsOnState[idx].loading = true;
        } else {
          //add new item on state with loading true
          itemsOnState.push({
            data: new CostingCenterDto({ id: costingCenterId }),
            lastUpdatedDate: undefined,
            loading: true,
          });
        }

        patchState(store, {
          cachedCostingCenters: itemsOnState,
        });
        const newData = await firstValueFrom(
          httpClientCostingCenter.costingCenterGetById(costingCenterId, httpContext).pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded || !newData.data) {
          let removedNotFoundedElement = [...store.cachedCostingCenters().filter(el => el.data?.id !== costingCenterId)];
          patchState(store, {
            cachedCostingCenters: { ...removedNotFoundedElement },
          });
          return;
        }

        const indexOfCostingCenterId = itemsOnState.findIndex(x => x.data?.id === costingCenterId);
        if (indexOfCostingCenterId > -1) {
          itemsOnState[indexOfCostingCenterId].data = newData.data;
          itemsOnState[indexOfCostingCenterId].loading = false;
          itemsOnState[indexOfCostingCenterId].lastUpdatedDate = moment();
          patchState(store, {
            cachedCostingCenters: [...itemsOnState],
          });
        } else {
          patchState(store, {
            cachedCostingCenters: [
              ...store.cachedCostingCenters().filter(el => el.data?.id !== costingCenterId),
              ...[
                {
                  data: newData.data,
                  lastUpdatedDate: moment(),
                  loading: false,
                },
              ],
            ],
          });
        }
      },

      loadActiveCurrencies: async (force_skip: boolean = false) => {
        if (!force_skip) {
          const uOpts = store.activeCurrencies();
          const existingData = GetStateData(uOpts, moment(), EXPIRE_API_SECONDS.CURRENCIES, store.skipCache().data);
          if (existingData) return;
        }
        //set loading to true
        patchState(store, {
          activeCurrencies: { ...store.activeCurrencies()!, loading: true },
        });
        const newData = await firstValueFrom(httpClientCurrencies.activeCurrencies(new Request2()).pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data || newData.data.length === 0) {
          patchState(store, {
            activeCurrencies: INITIAL_CONFIGURATIONS_STATE.activeCurrencies,
          });
          return;
        }

        patchState(store, {
          activeCurrencies: { data: newData.data, lastUpdatedDate: moment(), loading: false },
        });
      },

      loadApiVersion: async (force_skip: boolean = false) => {
        if (!force_skip) {
          const uOpts = store.apiVersion();
          const existingData = GetStateData(uOpts, moment(), EXPIRE_API_SECONDS.API_VERSION, store.skipCache().data);
          if (existingData) return;
        }
        //set loading to true
        patchState(store, {
          apiVersion: { ...store.apiVersion()!, loading: true },
        });
        const newData = await firstValueFrom(httpClientPublic.getVersion().pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data || newData.data.length === 0) {
          patchState(store, {
            apiVersion: INITIAL_CONFIGURATIONS_STATE.apiVersion,
          });
          return;
        }

        patchState(store, {
          apiVersion: { data: newData.data, lastUpdatedDate: moment(), loading: false },
        });
      },

      getCurrentUserId: async (force_skip: boolean = false) => {
        if (!force_skip) {
          const uOpts = store.currentUserId();
          const existingData = GetStateData(uOpts, moment(), EXPIRE_API_SECONDS.API_VERSION, store.skipCache().data);
          if (existingData) return;
        }
        //set loading to true
        patchState(store, {
          currentUserId: { ...store.currentUserId()!, loading: true },
        });
        const newData = await firstValueFrom(httpClientUser.getCurrentUserId().pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data || newData.data.length === 0) {
          patchState(store, {
            currentUserId: INITIAL_CONFIGURATIONS_STATE.currentUserId,
          });
          return;
        }

        patchState(store, {
          currentUserId: { data: newData.data, lastUpdatedDate: moment(), loading: false },
        });
      },

      loadEndingYearStarted: async (force_skip: boolean = false) => {
        if (!force_skip) {
          const uOpts = store.endingYearStarted();
          const existingData = GetStateData(uOpts, moment(), EXPIRE_API_SECONDS.ENDING_YEAR, store.skipCache().data);
          if (existingData !== undefined || store.endingYearCallStarted()) return;
        }
        //set loading to true
        patchState(store, {
          endingYearStarted: { ...store.endingYearStarted()!, loading: true },
          endingYearCallStarted: true,
        });

        const newData = await firstValueFrom(httpClientEndingYear.endingYearStarted().pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded) {
          patchState(store, {
            endingYearStarted: INITIAL_CONFIGURATIONS_STATE.endingYearStarted,
            endingYearCallStarted: false,
          });
          return;
        }

        patchState(store, {
          endingYearStarted: { data: newData.data, lastUpdatedDate: moment(), loading: false },
          endingYearCallStarted: false,
        });
      },

      loadDecimalPlaces: async (force_skip: boolean = false) => {
        if (!force_skip) {
          const uOpts = store.decimalPlaces();
          const existingData = GetStateData(uOpts, moment(), EXPIRE_API_SECONDS.DECIMAL_PLACES, store.skipCache().data);
          if (existingData) return;
        }
        //set loading to true
        patchState(store, {
          decimalPlaces: { ...store.decimalPlaces()!, loading: true },
        });
        const newData = await firstValueFrom(
          httpClientCompanyOptions.readCompanyOptionsById(CompanyOptionEnum.DECIMAL_ROUNDING).pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded || !newData.data) {
          patchState(store, {
            decimalPlaces: INITIAL_CONFIGURATIONS_STATE.decimalPlaces,
          });
          return;
        }

        patchState(store, {
          decimalPlaces: { data: Number(newData.data.value), lastUpdatedDate: moment(), loading: false },
        });
      },

      loadTransferShipment: async (force_skip: boolean = false) => {
        if (!force_skip) {
          const uOpts = store.transferShipment();
          const existingData = GetStateData(uOpts, moment(), EXPIRE_API_SECONDS.TRANSFER_SHIPMENT, store.skipCache().data);
          if (existingData) return;
        }
        //set loading to true
        patchState(store, {
          transferShipment: { ...store.transferShipment()!, loading: true },
        });
        const newData = await firstValueFrom(
          httpClientCompanyOptions
            .readCompanyOptionsById(CompanyOptionEnum.SALESINVOICE_GENERATE_TRANSFERSHIPMENT)
            .pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded) {
          patchState(store, {
            transferShipment: INITIAL_CONFIGURATIONS_STATE.transferShipment,
          });
          return;
        }

        patchState(store, {
          transferShipment: { data: newData.data?.value, lastUpdatedDate: moment(), loading: false },
        });
      },

      loadTransferReceipt: async (force_skip: boolean = false) => {
        if (!force_skip) {
          const uOpts = store.transferReceipt();
          const existingData = GetStateData(uOpts, moment(), EXPIRE_API_SECONDS.TRANSFER_RECEIPT, store.skipCache().data);
          if (existingData) return;
        }
        //set loading to true
        patchState(store, {
          transferReceipt: { ...store.transferReceipt()!, loading: true },
        });
        const newData = await firstValueFrom(
          httpClientCompanyOptions
            .readCompanyOptionsById(CompanyOptionEnum.PURCHASEINVOICE_GENERATE_TRANSFERRECEIPT)
            .pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded) {
          patchState(store, {
            transferReceipt: INITIAL_CONFIGURATIONS_STATE.transferReceipt,
          });
          return;
        }

        patchState(store, {
          transferReceipt: { data: newData.data?.value, lastUpdatedDate: moment(), loading: false },
        });
      },

      loadUseForFisalization: async (force_skip: boolean = false) => {
        if (!force_skip) {
          const uOpts = store.useForFisalization();
          const existingData = GetStateData(uOpts, moment(), EXPIRE_API_SECONDS.TRANSFER_RECEIPT, store.skipCache().data);
          if (existingData !== undefined) return;
        }
        //set loading to true
        patchState(store, {
          useForFisalization: { ...store.useForFisalization()!, loading: true },
        });
        const newData = await firstValueFrom(httpClientDevPosIntegration.getDevPosIdentifier().pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded) {
          patchState(store, {
            useForFisalization: INITIAL_CONFIGURATIONS_STATE.useForFisalization,
          });
          return;
        }

        patchState(store, {
          useForFisalization: { data: newData.data, lastUpdatedDate: moment(), loading: false },
        });
      },

      loadCostingCenters: async (force_skip: boolean = false) => {
        if (!force_skip) {
          const costingCenters = store.costingCenters();
          const existingData = GetStateData(costingCenters, moment(), EXPIRE_API_SECONDS.COSTINGCENTER, store.skipCache().data);
          if (existingData) return;
        }
        const newData = await firstValueFrom(
          httpClientCostingCenter
            .getAllOrFilteredCostingCenter(
              new CcGetAllOrFilteredRequest({
                page: new DynamicPage({
                  number: 1,
                  size: 9999,
                }),
              }),
            )
            .pipe(nswagCatchOperator(), take(1)),
        );
        if (!newData.succeeded || !newData.data) return;
        const dataFromApi = {
          data: newData.data.results,
          lastUpdatedDate: moment(),
          loading: false,
        };

        patchState(store, {
          costingCenters: dataFromApi,
        });
      },

      skipCacheRequest: async (skipCache: boolean = false, showToaster: boolean = false) => {
        if (!skipCache) {
          const skipC = store.skipCache();
          const existingData = GetStateData(skipC, moment(), EXPIRE_API_SECONDS.SKIP_CACHE, skipCache);
          if (existingData === undefined)
            patchState(store, {
              skipCache: { data: false, lastUpdatedDate: moment() },
            });
          return;
        }

        const translate = injector.get(TranslateService);
        const msg = showToaster
          ? translate.instant('general.skip_cache_enabled', {
              interval: EXPIRE_API_SECONDS.SKIP_CACHE / 60,
              interval_name: 'min',
            })
          : undefined;

        if (msg) toast.info(msg);

        // this.store.dispatch(ClearItemsCache());
        // this.store.dispatch(ClearInventoryCache());
        // this.store.dispatch(ClearUsersCache());
        // this.store.dispatch(ClearConfigurationCache());
        // this.userOptionsStore.clearUserOptions();
        // this.configurationsStore.clearConfigurations();
        // patchState(store, {
        //   skipCache:
        //   data: skipCache,
        //   lastUpdatedDate: moment(),
        // })

        patchState(store, {
          skipCache: { data: skipCache, lastUpdatedDate: moment() },
        });
      },

      clearConfigurations: () => {
        patchState(store, INITIAL_CONFIGURATIONS_STATE);
      },
    }),
  ),

  withStorageSync({
    key: 'configurations',
    autoSync: true,
  }),
  withDevtools('ConfigurationsStore'),
);
