import { computed, inject, Injectable } from '@angular/core';
import { HttpContextToken, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { IsApiUrl, IsAssetUrl, IsDevPosUrl } from '../../authorization/services/auth.util';
import { DevPosUrlInitialize } from '../services/devpos-url-initializer.service';
import { ConfigurationsStore } from 'src/app/state/configurations-store';

export const IGNORE_REDIS_CACHE = new HttpContextToken<boolean>(() => false);

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  readonly configurationsStore = inject(ConfigurationsStore);
  skipCache = computed(() => this.configurationsStore.skipCache().data);
  constructor(
    private store: Store,
    private devPosUrlInitialize: DevPosUrlInitialize,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    let skipCache = request.context.get(IGNORE_REDIS_CACHE);
    if (skipCache) {
      if (!this.shouldIntercept(request)) return next.handle(request);
      return next.handle(
        request.clone({
          setHeaders: {
            'x-ignore-cache': 'true',
          },
        }),
      );
    }

    if (!this.shouldIntercept(request)) return next.handle(request);

    if (!this.skipCache()) next.handle(request.clone({}));
    return next.handle(
      request.clone({
        setHeaders: {
          'x-ignore-cache': this.skipCache() ? 'true' : 'false',
        },
      }),
    );

    // return this.store.select(selectSkipCache(moment())).pipe(
    //   take(1),
    //   switchMap(skipCache => {
    //     return !skipCache
    //       ? next.handle(request.clone({}))
    //       : next.handle(
    //           request.clone({
    //             setHeaders: {
    //               'x-ignore-cache': skipCache ? 'true' : 'false',
    //             },
    //           }),
    //         );
    //   }),
    // );
  }

  private shouldIntercept(request: HttpRequest<unknown>) {
    return !IsAssetUrl(request) ? IsApiUrl(request) || IsDevPosUrl(request, this.devPosUrlInitialize.devPosInternalUrl) : false;
  }
}
