import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { computed, inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IsAssetUrl, IsSignalrUrl } from '../services/auth.util';
import { SignalRUrlInitialize } from 'src/app/shared/services/signalr-url-initializer.service';
import { AuthorizationStore } from 'src/app/state/authorization-store';

@Injectable()
export class SignalrInterceptor implements HttpInterceptor {
  readonly authorizationStore = inject(AuthorizationStore);

  contractId = computed(() => this.authorizationStore.companyDto!()?.contractId ?? undefined);

  constructor(
    private store: Store,
    private signalRUrlInitialize: SignalRUrlInitialize,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isAssetUrl = IsAssetUrl(request);
    if (isAssetUrl) return next.handle(request);

    const isSignalrUrl = IsSignalrUrl(request, this.signalRUrlInitialize.signalrUrl);
    if (!isSignalrUrl) return next.handle(request);

    const username = localStorage.getItem('attemptedUsername');
    if (!username) return next.handle(request);

    if (!this.contractId()) return next.handle(request);

    request = request.clone({
      setHeaders: {
        'X-Contract-Id': this.contractId()!,
        'X-Username': username,
      },
    });

    return next.handle(request);
  }
}
