import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { computed, inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, filter, Observable, of, switchMap, take, tap, throwError } from 'rxjs';

import { IsApiUrl, IsAssetUrl, IsDevPosUrl, IsSignalrUrl } from '../services/auth.util';
import { ApiWrapperResponseOfKeyValuePairOfStringAndString, HttpClientAuth, RefreshTokenRequest } from 'src/app/shared/nswag.api';
import { SignalRUrlInitialize } from 'src/app/shared/services/signalr-url-initializer.service';
import { DevPosUrlInitialize } from '../../shared/services/devpos-url-initializer.service';
import { AuthorizationStore } from 'src/app/state/authorization-store';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  bc: BroadcastChannel = new BroadcastChannel('refresh_token_channel');
  readonly authorizationStore = inject(AuthorizationStore);

  token = computed(() => this.authorizationStore.token() ?? undefined);
  refreshToken = computed(() => this.authorizationStore.refreshToken() ?? undefined);

  private isRefreshing = false;

  private userToken: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  constructor(
    private store: Store,
    private httpClientAuth: HttpClientAuth,
    private signalRUrlInitialize: SignalRUrlInitialize,
    private devPosUrlInitialize: DevPosUrlInitialize,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isAssetUrl = IsAssetUrl(request);
    if (isAssetUrl) return next.handle(request);

    const isApiUrl = IsApiUrl(request);
    const isSignalrUrl = IsSignalrUrl(request, this.signalRUrlInitialize.signalrUrl);
    const isDevPosUrl = IsDevPosUrl(request, this.devPosUrlInitialize.devPosInternalUrl);
    if (!isApiUrl && !isSignalrUrl && !isDevPosUrl) return next.handle(request);
    if (this.token()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token()}`,
        },
      });
    }
    return of(request).pipe(
      switchMap(req =>
        next.handle(req).pipe(
          catchError(err => {
            if (err.status === 401) {
              // nqs se refresh token kthen 401 atehere logout
              if (this.isRefreshing && req.url.includes('/api/Auth/refresh-token')) {
                this.authorizationStore.logout();
                // authorizationStore.logout();
                return throwError(err);
              }
              // nqs eshte 401 dhe nuk eshte refresh token atehere bej refresh token
              return this.handle401Error(request, next);
            } else {
              return throwError(err);
            }
          }),
        ),
      ),
    );
  }

  private handle401Error(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isRefreshing) {
      this.userToken.next(null);
      this.isRefreshing = true;
      return this.httpClientAuth.refreshToken(new RefreshTokenRequest({ refreshToken: this.refreshToken() })).pipe(
        tap((data: ApiWrapperResponseOfKeyValuePairOfStringAndString) => {
          const tokens = {
            token: data.data!.key as string,
            refreshToken: data.data!.value as string,
          };
          this.authorizationStore.onRefreshTokenSuccess(tokens);
          if (this.bc) this.bc.postMessage(tokens);
          this.userToken.next(data.data!.key as string);
          this.isRefreshing = false;
        }),
        switchMap(data => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${data.data!.key as string}`,
            },
          });
          return next.handle(request);
        }),
      );
    } else {
      return this.userToken.pipe(
        filter(token => token != null),
        take(1),
        tap(token => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }),
        switchMap(() => next.handle(request)),
      );
    }
  }
}
