import { Component, computed, inject, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { NavigationService } from './shared/services/navigation-service.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import packageInfo from '../../package.json';
import { DashboardStore } from './state/dashboard-store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy, OnInit {
  loadingRouteConfig: boolean = false;

  unsubscribe$ = new Subject<boolean>();

  dashboardStore = inject(DashboardStore);

  language = computed(() => this.translate.use(this.dashboardStore.language() ?? 'sq'));

  constructor(
    private router: Router,
    private translate: TranslateService,
    private store: Store,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private titleService: Title,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
      if (event instanceof GuardsCheckStart) {
        this.loadingRouteConfig = true;
      }
      if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
        this.loadingRouteConfig = false;
      }
    });
    this.navigationService.startSaveHistory();
  }

  ngOnInit() {
    this.dashboardStore.loadLanguage(undefined);
    this.setAppTitle();
  }
  setAppTitle() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe(data => {
        if (data['title']) this.titleService.setTitle('devBooks - ' + this.translate.instant(data['title']));
        else this.titleService.setTitle(packageInfo.name);
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
