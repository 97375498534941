import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { computed, inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IsApiUrl, IsAssetUrl, IsDevPosUrl, IsSignalrUrl } from '../services/auth.util';
import { SignalRUrlInitialize } from 'src/app/shared/services/signalr-url-initializer.service';
import { DevPosUrlInitialize } from '../../shared/services/devpos-url-initializer.service';
import { AuthorizationStore } from 'src/app/state/authorization-store';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  authorizationStore = inject(AuthorizationStore);
  companyDto = computed(() => this.authorizationStore.companyDto!() ?? undefined);

  constructor(
    private store: Store,
    private signalRUrlInitialize: SignalRUrlInitialize,
    private devPosUrlInitialize: DevPosUrlInitialize,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isAssetUrl = IsAssetUrl(request);
    if (isAssetUrl) return next.handle(request);

    const isApiUrl = IsApiUrl(request);
    const isSignalrUrl = IsSignalrUrl(request, this.signalRUrlInitialize.signalrUrl);
    const isDevPosUrl = IsDevPosUrl(request, this.devPosUrlInitialize.devPosInternalUrl);
    if (!isApiUrl && !isSignalrUrl && !isDevPosUrl) return next.handle(request);
    const tenantIdentifier = this.companyDto()
      ? `${this.companyDto()?.nipt}|${this.companyDto()?.year}|${this.companyDto()?.businessCode}|${this.companyDto()?.isDemo}`
      : undefined;
    if (!tenantIdentifier) return next.handle(request);
    request = request = request.clone({
      setHeaders: {
        'X-Tenant-Id': tenantIdentifier!,
      },
    });
    return next.handle(request);
  }
}
