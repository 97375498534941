import { computed, inject, Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { IsApiUrl, IsAssetUrl, IsDevPosUrl } from '../../authorization/services/auth.util';
import { DevPosUrlInitialize } from '../services/devpos-url-initializer.service';
import { DashboardStore } from 'src/app/state/dashboard-store';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  dashboardStore = inject(DashboardStore);
  language = computed(() => this.dashboardStore.language() ?? 'sq');

  constructor(
    private store: Store,
    private devPosUrlInitialize: DevPosUrlInitialize,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    const isAssetUrl = IsAssetUrl(request);
    if (isAssetUrl) return next.handle(request);

    const isApiUrl = IsApiUrl(request);
    const isDevPosUrl = IsDevPosUrl(request, this.devPosUrlInitialize.devPosInternalUrl);
    if (!isApiUrl && !isDevPosUrl) return next.handle(request);

    return next.handle(
      request.clone({
        setHeaders: {
          'Accept-Language': this.language() === 'sq' ? 'sq-AL' : 'en',
        },
      }),
    );
  }
}
