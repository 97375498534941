import { computed, inject, Injectable } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthorizationStore } from 'src/app/state/authorization-store';

export const LoggedInGuard: CanActivateFn = () => {
  return inject(LoggedInGuardService).canActivate();
};

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuardService {
  authorizationStore = inject(AuthorizationStore);
  userToken = computed(() => this.authorizationStore.token() ?? undefined);
  constructor(private router: Router) {}

  private get isLoggedIn() {
    if (!this.userToken()) {
      this.authorizationStore.logout();
      this.router.navigate(['/auth/login']);
      return of(false);
    }
    return of(true);
  }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isLoggedIn;
  }
}
